<template>
  <div>
    <BaseLoading style="min-height: 400px; height: 90vh;" v-if="loading"></BaseLoading>

    <div v-else class="text-left">
      <!-- <BaseHeader :title="'My Wallet'"></BaseHeader> -->

      <div class="card mt-2 border-0  bg-white shadow">
            <div class="card-body text-right d-flex" style="">
              <!-- <form>
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Start typing to search">
                  <span class="input-group-append">
                    <button class="btn btn-success" type="search">Search</button>
                  </span>
                </div>
              </form> -->
              <h3>My Wallet</h3>
              <div class="ml-auto">
                <button style=" border-radius:50px!important; " class="btn btn-success px-4 py-2 " data-toggle="modal" data-target="#walletModal">Topup Wallet</button>
                <h4 type="button" class="text-success btn-labeled ">
                    <strong>Balance ${{ wallet_balance.balance || 0 }}</strong> 
                </h4>
              </div>
            </div>

            <div class="table-responsive">
            
              <table class="table table-striped">
                <thead>
                  <tr class="text-left">
                    <th>#</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Credit</th>
                    <th>Debit</th>
                  </tr>
                </thead>
                <tbody v-if="wallet_entries.length > 0">
                  <tr class="" v-for="(wallet, index) in wallet_entries" :key="index + 'ords'">
                    <td class="text-left">{{ index+1 }}</td>
                    <td class="text-left"> 
                      <!-- <router-link :to="{name: 'wallet', params: { id: wallet.id }}"></router-link>  -->
                      {{ wallet.create_stamp || "" }}
                    </td>
                    <td class="text-left">{{ wallet.description || "" }}</td>
                    <td class="text-left" style="background-color: #b3d6b5;">{{ wallet.credit || "" }}</td>
                    <td class="text-left" style="background-color: #eaafaf;">{{ wallet.debit || "" }}</td>
                    
                  </tr>
                
                </tbody>
                <tfoot v-else>
								<tr>
									<td colspan="5"> <strong class="custom_imageEmpty" >No wallet entries.</strong> </td>
								</tr>
							</tfoot>
              </table>
                
            </div>
      </div>

    </div>

    <BaseModal :title="'Wallet Topup'" :modalId="'walletModal'">
      <WalletTopup></WalletTopup>
    </BaseModal>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import WalletTopup from '../../components/payments/modals/WalletTopup.vue'
export default {
  name: "Wallet",
  components: {
    WalletTopup
  }, 
  computed: {
    ...mapState("payment", ["wallet_entries", "loading", "wallet_balance"])
  },
  async mounted() {
    await this._getWalletEntries()
    await this._getWalletBalance()
  },
  methods: {
    ...mapActions("payment", ["_getWalletEntries", "_getWalletBalance"])
  }
};
</script>
